import React from 'react'
import CountUp from 'react-countup';
import './success.css';
const Success = () => {
    return (
        <>

            <div class="cta-area cta-area-bg bg-img" data-bg="assets/img/backgrounds/cta-bg2.webp">
                <div class="cta-wrapper background-color--dark-overlay space__inner__top--50 space__inner__bottom--150">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-7 mx-auto">
                                <div class="cta-block cta-block--default-color aos-init aos-animate" data-aos="fade-down" data-aos-duration="1500">
                                    <p class="cta-block__light-text text-center">Имате <span>Идеа</span>?</p>
                                    <p class="cta-block__semi-bold-text cta-block__semi-bold-text--medium text-center">Обадете се сега и нека я превърнем заедно в реалност!</p>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className='funfacts-elements-wrapper'>
                <div className="funfacts-elements">
                    <div className="row mt-n1-9">
                        <div className="col-6 col-lg-3  funfact-item">
                            <div
                                className="text-center wow fadeInUp"
                                data-wow-delay="100ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "100ms",
                                    animationName: "fadeInUp"
                                }}
                            >
                                <h6 className="text-primary display-15 display-xl-10">
                                    <span className="countup"><CountUp end={20} duration={2} enableScrollSpy={true} scrollSpyDelay={100}/></span>+
                                </h6>
                                <h3 className="mb-0 h5">Завършени Проекта</h3>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3  funfact-item">
                            <div
                                className="text-center wow fadeInUp"
                                data-wow-delay="200ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "200ms",
                                    animationName: "fadeInUp"
                                }}
                            >
                                <h6 className="text-primary display-15 display-xl-10">
                                    <span className="countup"><CountUp end={6} duration={2} enableScrollSpy={true} /></span>+
                                </h6>
                                <h3 className="mb-0 h5">Активни обекта</h3>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3  funfact-item">
                            <div
                                className="text-center wow fadeInUp"
                                data-wow-delay="300ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "300ms",
                                    animationName: "fadeInUp"
                                }}
                            >
                                <h6 className="text-primary display-15 display-xl-10">
                                    <span className="countup"><CountUp end={16} duration={1} enableScrollSpy={true} /></span>+
                                </h6>
                                <h3 className="mb-0 h5">Международни признания</h3>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3  funfact-item">
                            <div
                                className="text-center wow fadeInUp"
                                data-wow-delay="400ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "400ms",
                                    animationName: "fadeInUp"
                                }}
                            >
                                <h6 className="text-primary display-15 display-xl-10">
                                    <span className="countup"><CountUp end={8} duration={1} enableScrollSpy={true} scrollSpyDelay={200}/></span>+
                                </h6>
                                <h3 className="mb-0 h5">Години Опит</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Success;