import React from 'react';
import './our-team.css';
// import { Fade, Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css'
import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// 2712 - 3733
const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 400,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        1000: {
            autoplay: false,
            items: 3
        }
    }
};
const OurTeam = () => {
    return (
        <div class="container bg-light py-5 our-team">
            <div className="row">
                <div className="col-12 col-lg-12 order-2 image-column">
                    <div class="row mt-n1-9 g-5">
                        <ReactOwlCarousel className="owl-theme" {...options}>
                            <div class="mt-2 itemmt-2 item">
                                <div class="card card-style2 border-0 mt-md-7 aos-init aos-animate " data-aos="fade-in" data-aos-duration="800" >
                                    <div>
                                        <img src="assets/images/1465916C-38C9-48ED-9829-36F58640EF81-copy.webp" alt="..." class="border-radius-10" />
                                    </div>
                                    <div class="card-body">
                                        <div class="team-data">
                                            <h4><a href="team-details.html" class="text-white">Бернар Кемалов</a></h4>
                                            <span class="text-white">Изпълнителен Директор</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 itemmt-2 item">
                                <div class="card card-style2 border-0 aos-init aos-animate" data-aos="fade-in" data-aos-duration="1000" >
                                    <div>
                                        <img src="assets/images/IMG_9894-CROP.webp" alt="..." class="border-radius-10" />
                                    </div>
                                    <div class="card-body">

                                        <div class="team-data">
                                            <h4><a href="team-details.html" class="text-white">Стефан Благоев</a></h4>
                                            <span class="text-white">Главен Инженер / Технически Ръководител</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 itemmt-2 item">
                                <div class="card card-style2 border-0 aos-init aos-animate" data-aos="fade-in" data-aos-duration="600">
                                    <div>
                                        <img src="assets/images/855BB797-099D-4682-8F3C-634524A76111-CROP.webp" alt="..." class="border-radius-10" />
                                    </div>
                                    <div class="card-body">
                                        <div class="team-data">
                                            <h4><a href="team-details.html" class="text-white">Вили Великова</a></h4>
                                            <span class="text-white">Ръководител Продажби</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ReactOwlCarousel>
                    </div>
                </div>
                <div className="col-12 col-lg-12 order-1">
                    <div class="row justify-content-center g-0">
                        <div class="col-md-10 col-lg-10">
                            <h2 class="text-heading mb-4 ">Нашият екип</h2>
                            <p class="mb-5 text-center">
                                Нашият екип е съставен от професионалисти с богат опит и умения, които владеят различни езици- английски, немски, нидерландски, френски, турски и руски език.<br /> <br /> Ние разбираме колко важно е за чуждестранните инвеститори да комуникират свободно и без затруднения, затова гарантираме, че няма да има езикови бариери при работата с нас.<br /> <br /> Независимо дали сте инвеститор, който планира да влезе на българския пазар, или вече сте тук и се нуждаете от съдействие, ние можем да ви осигурим пълна подкрепа на вашия език.                            </p>
                        </div>
                    </div>
                    {/* <div class="row justify-content-center text-start">
                        <div class="col-12 col-md-6 py-4">
                            <img src='assets/images/engineer.png' alt='engineer' className='icon' />
                            <h5 class="card-title py-3">Нашата Мисия</h5>
                            <p class="card-text">

                                Да изграждаме здрави, устойчиви структури, които издържат изпитанието на времето, като същевременно поддържаме фокус върху безопасността, качеството и иновациите във всеки проект.
                                {/* To build strong, sustainable structures that stand the test of time, while maintaining a focus on safety, quality, and innovation in every project. 
                            </p>

                        </div>
                        <div class="col-12 col-md-6 py-4">

                            <img src='assets/images/vision.png' alt='vision' className='icon' />
                            <h5 class="card-title py-3">Our Vision</h5>
                            <p class="card-text">
                                To be the leading construction company known for transforming ideas into reality through superior craftsmanship and a dedication to excellence.
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default OurTeam;