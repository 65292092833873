import React from 'react'
import './callToAction.css';
export const CallToAction = () => {

    return (
        <div class="call-to-action">
            <a href="tel:00359899898029"><i class="fa fa-phone"></i></a>
        </div>
    )
}
