import React from 'react'

import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './why-us.css';

const WhyUs = () => {

    const options = {
        loop: true,
        margin: 10,
        nav: false,
        dots: true,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    };

    const benefits = [
        {
            title: "Доказан опит и високо качество",
            description: "С множество успешно завършени проекти и дългогодишен опит, ние сме се утвърдили като лидер в строителството. Нашето качество на работа и внимание към детайлите са без конкуренция.",
            iconSrc: "assets/images/experts.svg"
        },
        {
            title: "Модерно оборудване и технологии",
            description: "Ние използваме най-новото оборудване и иновативни технологии, които ни позволяват да осигурим отлично качество и ефективност на изпълнението.",
            iconSrc: "assets/images/high_tech.svg"
        },
        {
            title: "Висококвалифициран екип",
            description: "Нашите специалисти са сред най-добрите в индустрията, с дългогодишен опит и задълбочени познания в областта. Те работят с прецизност и професионализъм, за да гарантират най-добрите резултати за нашите клиенти.",
            iconSrc: "assets/images/team.svg"
        },
        {
            title: "Ангажимент към клиента",
            description: "Ние поставяме клиентите на първо място, като се стремим да надминем техните очаквания във всяка фаза на проекта. Съчетаваме персонализиран подход с висококачествено изпълнение, за да осигурим пълно удовлетворение.",
            iconSrc: "assets/images/trust.svg"
        }
        // ,
        // {
        //     title: "Устойчивост и дълготрайност",
        //     description: "Нашите проекти са създадени да издържат на изпитанията на времето и интензивния трафик, като осигуряват безопасност и комфорт за всички участници в движението",
        //     iconSrc: "assets/images/quality.svg"
        // }
    ];

    return (
        <div class="container py-5 why-choose-us">
            <h2 class="text-center mb-4 text-heading">Защо да изберете нас</h2>
            <ReactOwlCarousel className="owl-theme" {...options}>
                {benefits.map((benefit, index) => (
                    <div className="item" key={index}>
                        <div className="benefit-item p-4 bg-light h-100 shadow-sm rounded">
                            <div className="icon mb-3">
                                <img src={benefit.iconSrc} alt="benefit-icon" class="img-fluid" />
                            </div>
                            <h3 className="h5">{benefit.title}</h3>
                            <p>{benefit.description}</p>
                        </div>
                    </div>
                ))}
            </ReactOwlCarousel>
            {/* <div class="row text-center">
        <div class="col-md-6 col-lg-3 mb-4">
            <div class="benefit-item p-4 bg-light h-100 shadow-sm">
                <div class="icon mb-3">
                    <img src="assets/images/expertise-icon.png" alt="Expertise" class="img-fluid" />
                </div>
                <h3 class="h5">Expertise</h3>
                <p>Years of industry experience ensuring top-notch solutions for your projects.</p>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <div class="benefit-item p-4 bg-light h-100 shadow-sm">
                <div class="icon mb-3">
                    <img src="assets/images/quality-icon.png" alt="Quality" class="img-fluid" />
                </div>
                <h3 class="h5">Quality</h3>
                <p>We deliver unmatched quality in every aspect of our work, from materials to service.</p>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <div class="benefit-item p-4 bg-light h-100 shadow-sm">
                <div class="icon mb-3">
                    <img src="assets/images/innovation-icon.png" alt="Innovation" class="img-fluid" />
                </div>
                <h3 class="h5">Innovation</h3>
                <p>We leverage the latest technologies and innovative approaches to stay ahead.</p>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <div class="benefit-item p-4 bg-light h-100 shadow-sm">
                <div class="icon mb-3">
                    <img src="assets/images/customer-service-icon.png" alt="Customer Service" class="img-fluid" />
                </div>
                <h3 class="h5">Customer Service</h3>
                <p>Our team is dedicated to providing outstanding support and customer service.</p>
            </div>
        </div>
    </div> */}
        </div>
    )
}

export default WhyUs;