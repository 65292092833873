import React, { useEffect, useState } from "react";
import Menu from "../components/menu/Menu";
import "./landing.css";
import Process from "../components/process/Process";
import Footer from "../components/footer/Footer";

import { services } from "../data/services";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import WhyUs from "../components/why-us/WhyUs";
import OurTeam from "../components/our-team/OurTeam";
import { CallToAction } from "../components/common/CallToAction";
import Submenu from "../components/menu/Submenu";
import Success from "../components/success/Success";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

function Landing() {
  const navigate = useNavigate();
  const fadeImages = [
    {
      url: "assets/images/view-modern-construction-site (1).webp",
    },
    {
      url: "assets/images/wall1.webp",
    },
    {
      url: "assets/images/wall2.webp",
    },
  ];

  const scrollToAnchor = (event, page, section) => {
    event.preventDefault();
    navigate(page, { state: { section } });
  };

  return (
    <>
      <Menu />
      <Submenu />
      <Fade arrows={false} autoplay={true} duration={2500}>
        {fadeImages.map((fadeImage, index) => (
          <div
            className="main-top"
            id="home"
            style={{
              backgroundImage:
                "linear-gradient(116deg, #0535458f 55%, #1476978f 55%), url(" +
                fadeImage.url +
                ")",
            }}
          >
            <div className="main-banner row align-items-center justify-content-center">
              <div className="col-md-11 col-lg-10 col-xl-9 col-xxl-8 text-center py-5">
                <h1 className="mb-4">
                  Вашият<strong className="title-sm banner">ДОВЕРЕН</strong>
                  партньор в строителството
                </h1>
                <Link
                  onClick={(event) => scrollToAnchor(event, "/", "about-us")}
                  className="btn-learn"
                  data-aos="fade-left"
                >
                  Научи повече
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Fade>

      <section id="about-us">
        <div className="container">
          <div className="row position-relative  mt-n1 align-items-center">
            <div className="col-lg-6 mt-3 order-2 order-lg-1">
              <div className="image-about image-about-1"></div>
              <div className="row mb-5 mt-5 justify-content-center p-4">
                <div
                  className="col-sm-10 mt-2 about-area "
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <div className="d-md-flex">
                    <div className="flex-shrink-0 about-icon py-md-5">
                      <i className="fa fa-bookmark" aria-hidden="true"></i>
                    </div>
                    <div className="flex-grow-1 ps-3 my-4 my-md-0">
                      <h3 className="h5">Нашата сила</h3>
                      <p className="mb-0">
                        Ние поемаме всички етапи от строителния процес – от
                        първоначалното проектиране и планиране, през избора на
                        материали и строителните дейности, до финалните
                        довършителни работи и предаване на обекта „до ключ“.
                        Собствената ни механизация ни позволява да оптимизираме
                        строителните процеси и да предложим конкурентни цени на
                        нашите клиенти, извършвайки услуги във всички главни
                        градове в България
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-sm-10 mt-4 about-area"
                  data-aos="zoom-in-right"
                  data-aos-duration="1000"
                >
                  <div className="d-md-flex">
                    <div className="flex-shrink-0 about-icon py-md-5">
                      <i className="fa fa-gavel" aria-hidden="true"></i>
                    </div>
                    <div className="flex-grow-1 ps-3 my-4 my-md-0">
                      <h3 className="h5">Законосъобразност</h3>
                      <p className="mb-0">
                        В нашата строителна компания ние поставяме на първо
                        място спазването на всички действащи закони и регулации
                        в строителната индустрия. Ние работим в съответствие с
                        всички нормативни уредби, за да гарантираме
                        безопасността и законността на всеки проект.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-sm-10 mt-4 about-area"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="d-md-flex">
                    <div className="flex-shrink-0 about-icon py-md-5">
                      <i class="fa fa-id-card" aria-hidden="true"></i>
                    </div>
                    <div className="flex-grow-1 ps-3 my-4 my-md-0">
                      <h3 className="h5">Сертифицирана дейност</h3>
                      <p className="mb-0">
                        Сертификатите, които притежаваме, са резултат от нашия
                        дългогодишен опит и ангажираност към най-високите
                        стандарти в строителството. Всеки сертификат е
                        свидетелство за професионализма и стремежа ни към
                        постоянно усъвършенстване и иновации в областта
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-3 order-1 order-lg-2">
              <div className="ps-lg-2 ps-xl-7">
                <div className="section-title mb-3 position-relative ">
                  <h2 className="text-heading text-uppercase letter-spacing-4 d-block mb-4 font-weight-600">
                    ЗА НАС
                  </h2>
                  <h3 className="display-22 display-sm-18 display-md-16 display-lg-14 display-xl-10 mb-lg-2">
                    Предлагаме иновативни и ефективни
                    <span className="title-sm">РЕШЕНИЯ !</span>
                  </h3>
                </div>
                <p className="lead fst-italic text-primary font-weight-400 mb-3 pb-2 border-bottom">
                  Независимо от мащаба и сложността на проекта, ние сме готови
                  да предоставим най-добрите резултати.
                </p>
                <p className="mb-3 pb-2 border-bottom">
                  Хт Инженеринг е строителна компания с експертен опит в
                  проектирането и изграждането на жилищни сгради- от нискоетажно
                  до високоетажно строителство, инфраструктурни обекти, общински
                  съоръжения, пътища, железопътни линии, летища и др.
                  <br />
                  В обхвата на дейността ни се включват още проучване и
                  консултиране в областта на недвижимите имоти, архитектура и
                  СМР във всички сфери на строителството.
                  <br />
                </p>
                <div className="image-about image-about-2">
                  <div className="after"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="fun-facts" className="py-2">
        <Success />
      </section>
      <section id="our-team" className="py-1">
        <OurTeam />
      </section>
      <section id="why-us" className="py-0">
        <WhyUs />
      </section>

      <section className="services-area" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-heading text-uppercase  d-block mb-4 font-weight-600">
                  Услуги
                </h2>
                <h6 className="mb-3 pb-2 border-bottom">
                  Ние предлагаме пълна гама от услуги, обхващащи всички аспекти
                  на строителството.
                </h6>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row service-body p-2 p-md-0">
                <div className="col-lg-3 menu-container ">
                  <aside class="widget widget-nav-menu" data-aos="zoom-in">
                    <div class="row row-cols-2 row-cols-md-2 g-2">
                      {services.map((service) => {
                        return (
                          <div
                            class="col"
                            onClick={(event) => {
                              event.preventDefault();
                              navigate("/services", {
                                state: { service: service.title },
                              });
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div class="card h-100">
                              <div class="card-body">
                                <h5 class="card-title">{service.title}</h5>
                              </div>

                              <img
                                src={service.image}
                                class="card-img-top"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* <ul class="list-style3">
                      {services.map((service) => {
                        return (
                          <li key={service.id}>
                            <a
                              href=""
                              onClick={(event) => {
                                event.preventDefault();
                                navigate("/services", {
                                  state: { service: service.title },
                                });
                                window.scrollTo(0, 0);
                              }}
                            >
                              {service.title}
                            </a>
                          </li>
                        );
                      })}
                    </ul> */}
                  </aside>
                </div>
                <div className="col-lg-9 align-self-center">
                  <div className="row">
                    <div className="col-lg-5 align-self-center">
                      <div className="left-text">
                        <h4 className="mb-4">Качеството е в нашите ръце.</h4>
                        <p>
                          Ние създаваме модерни и енергийно ефективни решения,
                          които отговарят на най-високите стандарти за качество
                          и устойчивост. Нашият екип от професионалисти
                          гарантира завършени проекти с безупречна прецизност,
                          внимание към детайла и ангажираност към нуждите на
                          нашите клиенти.
                        </p>
                        <div>
                          <div className="main-white-button">
                            <Link
                              onClick={(event) =>
                                scrollToAnchor(event, "/contact", "")
                              }
                            >
                              Получи своята безплатна консултация.
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 align-self-center">
                      <div className="right-image pt-4 pt-md-2">
                        <img src="assets/images/services.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Process /> */}
      <section id="certificate">
        <div className="container">
          <div className="row">
            <div className="col-8 col-sm-8 col-md-5 mx-auto">
              <img
                src="assets/images/cert.png"
                alt="certificate"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <CallToAction />
      <Footer />
    </>
  );
}

export default Landing;
