export const services = [
  {
    id: 1,
    title: "АРХИТЕКТУРНО ПРОЕКТИРАНЕ",
    image: "assets/images/АРХИТЕКТУРНО ПРОЕКТИРАНЕ.webp",
    content: (() => (
      <>
        <div className="container text-start">
          <div className="row">
            <div className="col-md-12">
              <p className=" px-2">
                Създаваме функционални архитектурни решения, съобразени с вашите
                предпочитания и спецификации. Проектираме, изграждаме и оформяме
                документално обекти с различно предназначение – от жилищни
                сгради до търговски и обществени съоръжения. Нашите проекти се
                отличават с иновативен дизайн и високо качество
              </p>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12">
              <h4>Основни етапи на архитектурното проектиране:</h4>
              <ul class="list-style1 pt-4 px-2">
                <li>
                  <b className="header">Концептуален дизайн :</b>
                  <p className="pt-2">
                    В началния етап нашите архитекти работят в тясно
                    сътрудничество с клиента, за да създадат концептуален
                    дизайн, който отразява визията и целите на проекта. Този
                    етап включва разработване на първоначални скици, планове и
                    3D визуализации, които помагат на клиента да добие ясна
                    представа за бъдещия обект.
                  </p>
                </li>
                <li>
                  <b className="header">Разработване на проект :</b>
                  <p className="pt-2">
                    След одобрение на концепцията преминаваме към детайлно
                    разработване на проекта, където всеки аспект на сградата се
                    проектира с прецизност и внимание към детайлите. Това
                    включва архитектурни планове, фасади, интериорни решения и
                    материали, които ще бъдат използвани.
                  </p>
                </li>
                <li>
                  <b className="header">Интеграция на инженерни системи :</b>
                  <p className="pt-2">
                    Съгласуваме архитектурния проект с всички необходими
                    инженерни системи като електрически инсталации, ВиК,
                    отопление, вентилация и климатизация. Тази интеграция е
                    ключова за осигуряване на функционалността и енергийната
                    ефективност на сградата.
                  </p>
                </li>
                <li>
                  <b className="header">Одобрение и финализация :</b>
                  <p className="pt-2">
                    След като всички аспекти на проекта са изцяло разработени,
                    предоставяме окончателните планове за одобрение от клиента и
                    започваме процеса на издаване на разрешителни и
                    документация.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-12 pt-4">
              <h4>Пълна документация за изграждане на обект</h4>
              <p className=" px-2">
                Ние предлагаме комплексни услуги за изготвяне на пълната
                документация, необходима за изграждане на обект, която включва
                всички необходими разрешителни, технически спецификации и
                координация с отговорните институции.
              </p>
            </div>
          </div>
        </div>
      </>
    ))(),
  },
  {
    id: 2,
    title: "СТРОИТЕЛНО ИЗГРАЖДАНЕ",
    image: "assets/images/СТРОИТЕЛНО ИЗГРАЖДАНЕ.webp",
    content: (() => (
      <>
        <div>
          <p>
            Ние поемаме отговорността за цялостния строителен процес,
            осигурявайки висококачествено изпълнение на всеки етап. С нашите
            професионалисти и модерна техника, гарантираме навременно и бюджетно
            изграждане на вашия проект. Фирмата ни изготвя задания с
            количествено-стойностна сметка, изпълнява целия процес - от груб
            строеж до довършителни работи, както и оказваме съдействие за всички
            актувания до предаване на ключ.
          </p>
          <p>
            Нашата компания има доказан опит в изграждането на различни видове
            жилищни сгради – от еднофамилни домове до многоетажни апартаментни
            комплекси. Ние разбираме, че всяко жилище е не просто сграда, а дом,
            който трябва да бъде удобен, функционален и естетически
            привлекателен.
          </p>
          <p>
            Нашата компания също така е специализирана в изграждането на различни
            видове общински съоръжения, включително училища, детски градини,
            болници, спортни комплекси и други обществени сгради. Ние разбираме
            колко важна е тази инфраструктура за обществото и се стремим да
            осигурим функционални, безопасни и естетически съоръжения, които да
            служат на обществото в дългосрочен план.
          </p>
        </div>
      </>
    ))(),
  },
  {
    id: 3,
    title: "АСФАЛТИРАНЕ НА ПЪТИЩА",
    image: "assets/images/АСФАЛТИРАНЕ НА ПЪТИЩА.webp",
    content: (() => (
      <>
        <div className="container text-start">
          <div className="row">
            <div className="col-md-12">
              <p>
                Разполагаме с квалифициран екип от професионалисти. Спазваме
                всички изисквания при полагането на асфалт. <br />
                <br />
                Стриктно спазваме процеса и технологията на асфалтирането и
                извършваме подготовка на терена, дренаж, уплътняване на
                настилката, полагане на основа - чакъл, проверка на техническите
                характеристики и полагане на асфалтовата настилка. <br />
                <br />
                Разполагаме със специализирана модерна техника вибрационен
                валяк, пътна фреза, комбиниран багер, асфалтополагаща машина,
                самосвали, челен товарач и други.
              </p>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12">
              <h4>Поддръжка и управление на пътища:</h4>
              <ul class="list-style1 pt-4 px-2">
                <li>
                  <b className="header">Регулярна поддръжка :</b>
                  <p className="pt-2">
                    Ние предлагаме услуги за регулярна поддръжка на пътища,
                    включително почистване, контрол на отводнителни системи и
                    други необходими дейности, които помагат за удължаване на
                    живота на пътната настилка и запазване на нейната
                    функционалност.
                  </p>
                </li>
                <li>
                  <b className="header">Прогнозна поддръжка :</b>
                  <p className="pt-2">
                    Използваме най-модерни технологии за мониторинг на
                    състоянието на пътната настилка, което ни позволява да
                    предвиждаме и планираме необходимите ремонтни дейности. Това
                    предотвратява появата на по-сериозни проблеми и осигурява
                    дългосрочно качество.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    ))(),
  },
  {
    id: 4,
    title: "ИЗГРАЖДАНЕ НА ДЕТСКИ ПЛОЩАДКИ И ЯСЛИ",
    image: "assets/images/ИЗГРАЖДАНЕ НА ДЕТСКИ ПЛОЩАДКИ И ЯСЛИ.webp",
    content:
      "Нашата компания е специализирана в проектирането и изграждането на детски площадки, ясли и спортни зони, които отговарят на най-високите стандарти за качество и безопасност.",
  },
  {
    id: 5,
    title: "СПОРТНИ ПЛОЩАДКИ",
    image: "assets/images/СПОРТНИ ПЛОЩАДКИ.webp",
    content:
      "Създаваме модерни и безопасни спортни площадки, които насърчават активния начин на живот и развиват спортните умения на хората от всички възрасти.",
  },
  {
    id: 6,
    title: "ВЕРТИКАЛНА ПЛАНИРОВКА",
    image: "assets/images/ВЕРТИКАЛНА ПЛАНИРОВКА.webp",
    content: (() => (
      <>
        <p>
          Ние предлагаме цялостни решения за изграждане на паркове и алеи,
          включващи:
          <br />
          <br /> Планиране и проектиране на зелени площи и паркове. Изграждане
          на алеи, пътеки и инфраструктура. Ландшафтен дизайн и озеленяване.
          Монтаж на осветление, пейки, беседки и други паркови съоръжения.
          Поддръжка и обновяване на съществуващи паркови площи
        </p>
      </>
    ))(),
  },
  {
    id: 7,
    title: "САНИРАНЕ НА СГРАДИ",
    image: "assets/images/САНИРАНЕ НА СГРАДИ.webp",
    content:
      "Специализираме се в саниране на сгради, осигурявайки по-добра енергийна ефективност, комфорт и дълготрайна защита",
  },
  {
    id: 8,
    title: "КОРЕКЦИЯ НА РЕЧНИ КОРИТА",
    image: "assets/images/КОРЕКЦИЯ НА РЕЧНИ КОРИТА_2.webp",
    content:
      "Изпълняваме корекции на речни корита за управление на водния поток и предотвратяване на наводнения, осигурявайки дълготрайна стабилност и безопасност",
  },
  {
    id: 9,
    title: "ЖЕЛЕЗОПЪТНА ИНФРАСТРУКТУРА",
    image: "assets/images/ЖЕЛЕЗОПЪТНА ИНФРАСТРУКТУРА.webp",
    content:
      "Нашата компания е водеща в реконструкцията и модернизацията на съществуващи железопътни линии. Независимо дали става въпрос за обновяване на стари трасета или внедряване на нови технологии за управление на трафика, ние предлагаме решения, които подобряват ефективността и безопасността на железопътната мрежа.",
  },
  {
    id: 10,
    title: "ИЗГРАЖДАНЕ НА БУНКЕРИ",
    image: "assets/images/ИЗГРАЖДАНЕ НА БУНКЕРИ.webp",
    content: (() => (
      <>
        <p>
          Нашият екип притежава дългогодишен опит в проектирането и
          строителството на укрепени съоръжения. Познаваме всички детайли и
          изисквания, за да създадем безопасни и надеждни бункери.
          <br />
          <br />
          Предлагаме индивидуални решения, съобразени с конкретните нужди на
          клиента, което гарантира, че бункерът отговаря на всички изисквания за
          сигурност и комфорт.
          <br />
          <br />
          Строим бункери под двора на къщи, в основите на новостроящи се такива
          или на посочено от клиента място. Мисията ни е да създаваме сигурност
          и защита. Изготвяме индивидуални проекти, които отговарят на всички
          изисквания за безопастност.
        </p>
      </>
    ))(),
  },
];
